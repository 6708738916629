import React, { Suspense } from 'react'
import Link from 'next/link'
import classNames from 'classnames'
import { translate, Trans } from 'react-i18next'
import { Link as Scroll } from 'react-scroll'
import { Container, Row, Col, Dropdown, DropdownButton } from './../../static/theme'


class Header extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      fixed: false
    };
  }

  componentDidMount(){

    this.handleFixed();

    /*
    ** @Listener
    ** @Description: Scroll listener
    */

    window.addEventListener('scroll', this.handleFixed);

  }

  /*
  ** @Function
  ** @Description: Fixed element on scroll
  */

  handleFixed = () => {

    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

    this.setState({ fixed: winScroll >= 90 ? true : false });

  }

  render(){

    const { fixed } = this.state;
    const { position, t, i18n } = this.props;

    return(

      <div className={ classNames("ds--header", `ds--header__${ position }`, fixed ? 'ds-header__background' : null) }>
        <header>

          <Container>

            <Row>

              <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                <div className="ds-header_top">
                  <div className="ds-header_left">
                    <h1 className="ds--hader_brand">
                      <Link href="/">
                        <a><img src="../../static/images/brand-intrasites-by-wiper-white.png" className="img-fluid"/></a>
                      </Link>
                    </h1>
                  </div>
                  <div className="ds-header_right--sm">
                  <ul className="list-unstyled disabled-scroll">
                    <li>
                      <Link href="https://demo.intrasites.com/">
                        <a title="{ t('translation:navigation.demo') }" className="btn btn-sm btn-primary not-contain-hover" target="_blank">
                          { this.props.t('translation:navigation.demo') }
                        </a>
                      </Link>
                    </li>
                    <li>
                      <DropdownButton
                        alignRight
                        title={ <i className="fa fa-globe"></i>}
                        size="sm"
                        variant="secondary"
                        id="dropdown-menu-align-right"
                      >
                        <Dropdown.Item eventKey="1" onClick={() => i18n.changeLanguage('es')}>
                          Español
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="2" onClick={() => i18n.changeLanguage('en')}>
                          English
                        </Dropdown.Item>
                      </DropdownButton>
                    </li>
                  </ul>
                </div>
                </div>
              </Col>

              <Col xl={9} lg={9} md={9} sm={12} xs={12}>
                <div className="ds-header_right">
                  <nav className="ds--header_nav">
                    <ul className="list-unstyled disabled-scroll">
                      <li>
                        <Scroll
                          activeClass="active"
                          to="comunication"
                          spy={true}
                          smooth={true}
                          duration={500}
                          offset={-100}
                          title={ t('translation:navigation.comunication') }>
                            { t('translation:navigation.comunication') }
                        </Scroll>
                      </li>
                      <li>
                        <Scroll
                          activeClass="active"
                          to="comunication"
                          spy={true}
                          smooth={true}
                          duration={500}
                          offset={-100}
                          title={ t('translation:navigation.collaboration') }>
                            { t('translation:navigation.collaboration') }
                        </Scroll>
                      </li>
                      <li>
                        <Scroll
                          activeClass="active"
                          to="comunication"
                          spy={true}
                          smooth={true}
                          duration={500}
                          offset={-100}
                          title={ t('translation:navigation.productivity') }>
                            { t('translation:navigation.productivity') }
                        </Scroll>
                      </li>
                      {/* 
                      <li>
                        <Scroll
                          activeClass="active"
                          to="pricing"
                          spy={true}
                          smooth={true}
                          duration={500}
                          title={ t('translation:navigation.pricing') }>
                            { t('translation:navigation.pricing') }
                        </Scroll>
                      </li>*/}
                      <li className="ds--header_nav--hidden-sm">
                        <Link href="https://demo.intrasites.com/">
                          <a title="{ t('translation:navigation.demo') }" className="btn btn-sm btn-primary not-contain-hover" target="_blank">
                            { this.props.t('translation:navigation.demo') }
                          </a>
                        </Link>
                      </li>
                      <li className="ds--header_nav--hidden-sm">
                        <DropdownButton
                          alignRight
                          title={ <i className="fa fa-globe"></i>}
                          size="sm"
                          variant="secondary"
                          id="dropdown-menu-align-right"
                        >
                          <Dropdown.Item eventKey="1" onClick={() => i18n.changeLanguage('es')}>
                            Español
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="2" onClick={() => i18n.changeLanguage('en')}>
                            English
                          </Dropdown.Item>
                        </DropdownButton>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Col>

            </Row>

          </Container>

        </header>
      </div>

    )
  }

}

export default translate('translation')(Header)
